<template>
  <!-- 违规停车 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="停车场：">
          <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
            placeholder="请选择停车场" @change="onSubmit">
            <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称：">
          <el-input maxlength="30" placeholder="请输入设备名称" v-model="queryForm.device_name"
            @input="(e) => (queryForm.device_name = validSpecialChar(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="告警类型：">
          <el-select popper-class="my-select" clearable v-model="queryForm.car_alarm_type" placeholder="请选择告警类型" @change="queryForm.car_alarm_type = queryForm.car_alarm_type
            ? queryForm.car_alarm_type
            : null;
          onSubmit();
          ">
            <el-option v-for=" item  in  $store.getters.getOptions('CarAlarmType') " :key="item.value"
              :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="告警时间：">
          <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
            start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
          </el-date-picker>
          <!-- <el-date-picker v-model="queryForm.start_create_time" type="datetime" placeholder="请选择开始时间">
            </el-date-picker>
            <span> ~ </span>
            <el-date-picker v-model="queryForm.end_create_time" type="datetime" placeholder="请选择结束时间">
            </el-date-picker> -->
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="违规停车">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="device_type">
              {{ getWayName("DeviceType", row.device_type) }}
            </template>
            <template slot-scope="{row}" slot="car_alarm_type">
              <span>{{
                getWayName("CarAlarmType", row.car_alarm_type)
              }}</span>
            </template>
            <template slot-scope="{row}" slot="alarm_time">
              <span>{{ validDateTime(row.alarm_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="status">
              <span :style="row.status == 2 ? '' : 'color:#ee0000;'">{{
                row.status == 2 ? "已处理" : "待处理"
              }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="err" icon="daichuli" hint="处理" @click=" handleit(row)" v-if="row.status == 1 && isShowBtn('0138dd04a66349819ace2752')
                "></lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click=" lookingfor(row)" v-if="row.status == 2">
              </lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data=" tableData " align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="parking_name" label="停车场"></el-table-column>
            <el-table-column prop="parking_space_name" label="车位编号"></el-table-column>
            <el-table-column prop="device_name" label="设备名称"></el-table-column>
            <el-table-column label="设备类型">
              <template slot-scope="scope">
                {{ getWayName("DeviceType", scope.row.device_type) }}
              </template>
            </el-table-column>
            <el-table-column prop="car_alarm_type" label="告警类型">
              <template slot-scope="scope">
                <span>{{
                  getWayName("CarAlarmType", scope.row.car_alarm_type)
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="alarm_time" label="告警时间">
              <template slot-scope="scope">
                <span>{{ validDateTime(scope.row.alarm_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span :style=" scope.row.status == 2 ? '' : 'color:#ee0000;' ">{{
                  scope.row.status == 2 ? "已处理" : "待处理"
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <lbButton type="err" icon="daichuli" hint="处理" @click=" handleit(scope.row) " v-if="
                  scope.row.status == 1 && isShowBtn('0138dd04a66349819ace2752')
                "></lbButton>
                <lbButton type="succes" icon="chakan" hint="查看" @click=" lookingfor(scope.row) "
                  v-if=" scope.row.status == 2 "></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change=" handleSizeChange " @current-change=" handleCurrentChange "
            :current-page=" queryForm.PageIndex " :page-sizes=" [10, 20, 30, 40] " :page-size=" queryForm.PageSize "
            layout="total, sizes, prev, pager, next, jumper" :total=" TotalCount ">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog append-to-body :title="DialogVisibleTitle" :isShow="centerDialogVisible"
      @close="centerDialogVisible = false" width="45%" footerSlot>
      <el-form label-position="right" ref="detailFormRef" label-width="120px" :model="detailForm" class="detailForm"
        :rules="detailFormRules">
        <div class="dialog_content_box">
          <div class="left_box">
            <el-form-item label="停车场：">{{
              detailForm.parking_name
            }}</el-form-item>
            <el-form-item label="车位编号：">{{
              detailForm.parking_space_name
            }}</el-form-item>
            <el-form-item label="设备名称：">{{
              detailForm.device_name
            }}</el-form-item>
            <el-form-item label="设备类型： ">
              {{
                getWayName("DeviceType", detailForm.device_type)
              }}</el-form-item>
            <el-form-item label="告警类型：">{{
              getWayName("CarAlarmType", detailForm.car_alarm_type)
            }}</el-form-item>
            <el-form-item label="告警时间：">{{
              validDateTime(detailForm.alarm_time)
            }}</el-form-item>
          </div>
          <div class="right_box" v-if="detailForm.alarm_pic">
            <el-image :z-index="2500" :src="formatImage(detailForm.alarm_pic)[0]"
              :preview-src-list="formatImage(detailForm.alarm_pic)">
              <div slot="error" class="image-slot">
                <i class="iconfont lebo-picerr"></i>
                <span>加载失败</span>
              </div>
            </el-image>
          </div>
        </div>
        <el-divider></el-divider>
        <div v-if="detailForm.status == 2">
          <el-form-item label="处理人：">{{
            detailForm.deal_with_str
          }}</el-form-item>
          <el-form-item label="处理时间：">{{
            validDateTime(detailForm.deal_with_time)
          }}</el-form-item>
          <el-form-item label="处理结果：">{{
            detailForm.deal_with_msg
          }}</el-form-item>
        </div>
        <div v-else>
          <el-form-item label="处理结果：" prop="deal_with_msg">
            <el-input type="textarea" :rows="5" placeholder="请详情描述..." v-model="detailForm.deal_with_msg">
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click=" centerDialogVisible = false ">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" v-if="detailForm.status == 1" @click=" sureit()">保 存</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import { getCarAlarmList, setHandleAlarm } from '@/api/administrative'
import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: null,
        device_name: '',
        car_alarm_type: null,
        alarm_time_start: '',
        alarm_time_end: '',
        PageSize: 10,
        PageIndex: 1
      },
      timeduan: '',
      // 表格数据
      tableData: [],
      TotalCount: 0,
      DialogVisibleTitle: '',
      centerDialogVisible: false,
      detailForm: {},
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '车位编号',
            prop: 'parking_space_name'
          }, {
            label: '设备名称',
            prop: 'device_name'
          }, {
            label: '设备类型',
            prop: 'device_type',
            slot: true
          }, {
            label: '告警类型',
            prop: 'car_alarm_type',
            slot: true
          }, {
            label: '告警时间',
            prop: 'alarm_time',
            slot: true
          }, {
            label: '状态',
            prop: 'status',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      detailFormRules: {
        deal_with_msg: [
          { required: true, message: '请输入详情描述', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName']),
    ...mapState(['parkingList'])
  },
  created () {
    this.fnGetCarAlarmList()
  },
  watch: {
    timeduan (newV, oldV) {
      if (newV) {
        this.queryForm.alarm_time_start = newV[0]
        this.queryForm.alarm_time_end = newV[1]
      } else {
        this.queryForm.alarm_time_start = ''
        this.queryForm.alarm_time_end = ''
        this.onSubmit()
      }
    }
  },
  mounted () { },
  methods: {
    async fnGetCarAlarmList () {
      const res = await getCarAlarmList(this.queryForm)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
      } else {
        this.tableData = []
        this.TotalCount = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetCarAlarmList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetCarAlarmList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetCarAlarmList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetCarAlarmList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetCarAlarmList()
    // },
    handleit (item) {
      // 打开处理异常对话框
      // console.log('处理异常---', item);
      this.detailForm = item
      this.DialogVisibleTitle = '异常处理'
      this.centerDialogVisible = true
      this.detailForm.deal_with_msg = ''
    },
    lookingfor (item) {
      // 查看处理结果对话框
      // console.log('处理异常结果---', item);
      this.detailForm = item
      this.DialogVisibleTitle = '详情'
      this.centerDialogVisible = true
    },
    setImgArray (img) {
      var list = []
      list.push(img)
      return list
    },
    sureit () {
      // 异常处理请求
      this.$refs.detailFormRef.validate(async (valid) => {
        if (valid) {
          var obj = {}
          obj._id = this.detailForm._id
          obj.deal_with_msg = this.detailForm.deal_with_msg
          const res = await setHandleAlarm(obj)
          // console.log(res);
          if (res && res.Code === 200) {
            this.$msg.success(res.Message)
          }
          this.fnGetCarAlarmList()
          this.centerDialogVisible = false
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.dialog_content_box {
  display: flex;
  justify-content: space-between;
}

.detailForm {
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
}

.right_box {
  .el-image {
    height: 200px;
  }

  /deep/ .el-image__inner,
  /deep/ .el-image__preview,
  /deep/ .el-image__placeholder {
    width: auto;
  }

  /deep/ .image-slot {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f7fa;
    color: #999;

    i {
      font-size: 24px;
    }
  }
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px!important;
}
</style>
